import { useMemo } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCartContext } from '@context';
import { calculateDiscount } from '@utils/discount';
import { DISCOUNT_RATES, PRODUCT_TYPES, useIsDiscountEligible, useIsVipMembership } from '..';

interface UseDiscountedPriceProps {
	price: number;
	discountRate?: number;
	compareAtPrice?: number;
	type?: string;
	mandatoryDiscount?: boolean;
}

const useDiscountedPrice = ({
	price,
	discountRate = DISCOUNT_RATES.PERCENT_10,
	compareAtPrice,
	type,
	mandatoryDiscount = false,
}: UseDiscountedPriceProps): [number, number, boolean] => {
	const { applySubscriptionDiscount } = useCartContext();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isDiscountEligible = useIsDiscountEligible({
		compareAtPrice,
		type,
		mandatoryDiscount,
	});

	const { applyDiscountMembership } = useIsVipMembership(type);
	const newDiscountRate = isV2Subscription ? DISCOUNT_RATES.PERCENT_15 : discountRate;

	const { discountedPrice, newCompareAtPrice } = useMemo(() => {
		if (isDiscountEligible || applyDiscountMembership) {
			return { discountedPrice: calculateDiscount(newDiscountRate, price), newCompareAtPrice: price };
		}
		return { discountedPrice: price, newCompareAtPrice: compareAtPrice };
	}, [
		price,
		applySubscriptionDiscount,
		discountRate,
		isV2Subscription,
		compareAtPrice,
		isDiscountEligible,
		applyDiscountMembership,
	]);
	return [discountedPrice, newCompareAtPrice, isDiscountEligible];
};

export default useDiscountedPrice;
